<template>
     <div class="body">
          <div class="container">
               <div class="columns">
                    <div class="column is-6 ">
                         <div class="section-title">Certifications and Partners</div>
                         <div class="section-header " style="margin-bottom:15px; max-width:500px;">
                              Designed With Resilience And Intuition.
                         </div>
                         <p class="pb-5">
                              We have joined forces with the best in the industry to ensure superior project quality and assurance. Our global
                              certifications give us the singular edge to inculcate the habit of providing the highest quality of service that is
                              updated regularly through industry event representations and consistent learning.
                         </p>
                    </div>
                    <div class="column is-6 logos">
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert1.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert2.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert3.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert4.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert5.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert6.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert7.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert8.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert9.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert10.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert11.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert12.png" alt="" />
                              </div>
                         </div>
                    </div>
               </div>
               <div class="columns logos pt-3 ">
                    <div class="column ">
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert13.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert14.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert15.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert16.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert17.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert18.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert19.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert20.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert21.png" alt="" />
                              </div>
                         </div>
                    </div>

                    <div class="column p-0">
                         <div class=" columns is-mobile">
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert22.png" alt="" />
                              </div>
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert23.png" alt="" />
                              </div>
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert24.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert25.png" alt="" />
                              </div>
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert26.png" alt="" />
                              </div>
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert27.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert28.png" alt="" />
                              </div>
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert29.png" alt="" />
                              </div>
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert30.png" alt="" />
                              </div>
                         </div>
                    </div>
                 
               </div>
               <div class="columns logos pt-3 ">
                    <div class="column ">
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert31.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert32.png" alt="" />
                              </div>
                              <div class=" column">
                                   <img src="@/assets/images/certifications/cert33.png" alt="" />
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <!-- <img src="@/assets/images/certifications/cert34.png" alt="" /> -->
                              </div>
                              <div class=" column">
                                   <!-- <img src="@/assets/images/certifications/cert35.png" alt="" /> -->
                              </div>
                              <div class=" column">
                                   <!-- <img src="@/assets/images/certifications/cert18.png" alt="" /> -->
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class=" column">
                                   <!-- <img src="@/assets/images/certifications/cert19.png" alt="" /> -->
                              </div>
                              <div class=" column">
                                   <!-- <img src="@/assets/images/certifications/cert20.png" alt="" /> -->
                              </div>
                              <div class=" column">
                                   <!-- <img src="@/assets/images/certifications/cert21.png" alt="" /> -->
                              </div>
                         </div>
                    </div>

                    <div class="column p-0">
                         <div class=" columns is-mobile">
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert34.png" alt="" />
                              </div>
                              <div class="  column">
                                   <img src="@/assets/images/certifications/cert35.png" alt="" />
                              </div>
                              <div class="  column">
                                   <!-- <img src="@/assets/images/certifications/cert24.png" alt="" /> -->
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class="  column">
                                   <!-- <img src="@/assets/images/certifications/cert25.png" alt="" /> -->
                              </div>
                              <div class="  column">
                                   <!-- <img src="@/assets/images/certifications/cert26.png" alt="" /> -->
                              </div>
                              <div class="  column">
                                   <!-- <img src="@/assets/images/certifications/cert27.png" alt="" /> -->
                              </div>
                         </div>
                         <div class="columns is-mobile">
                              <div class="  column">
                                   <!-- <img src="@/assets/images/certifications/cert28.png" alt="" /> -->
                              </div>
                              <div class="  column">
                                   <!-- <img src="@/assets/images/certifications/cert29.png" alt="" /> -->
                              </div>
                              <div class="  column">
                                   <!-- <img src="@/assets/images/certifications/cert30.png" alt="" /> -->
                              </div>
                         </div>
                    </div>
                 
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped>
     .body {
          padding-top: 46px;

          padding-bottom: 120px;

          @media screen and (max-width: 1023px) {
               padding-top: 20px;
          }
     }
     .sideimg {
          width: 100%;
          @media screen and (max-width: 1023px) {
               margin-top: 40px;
          }
     }
  
     .section-title {
          margin-top: 10px;
          margin-bottom: 20px;
     }
     p {
          padding-top: 30px;
          max-width: 600px;
     }
     .details {
          padding-top: 150px;
     }
     .knowmore {
          font-size: 17px;
     }
     .logos {
          img {
              
          }
     }
</style>
